import { format } from 'date-fns';
import { useState, useEffect } from "react";

function ConnectionsTable({ data, is_pending }) {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (!is_pending && data) {
      setTableData(data);
    }
  }, [is_pending, data]);

  if (is_pending) {
    return <p></p>;
  }

  if (!data || data.length === 0) {
    return <p>No connections found.</p>;
  }

  const columns = [
    { label: "Nummer", accessor: "identifier" },
    { label: "Von", accessor: "station_from_name" },
    { label: "Nach", accessor: "station_to_name" },
    { label: "Abfahrt", accessor: "leaves" },
    { label: "Ankunft", accessor: "arrives" },
    { label: "Dauer", accessor: "duration" },
  ];

  const handleSortingChange = (accessor) => {
   const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";

   setSortField(accessor);
   setOrder(sortOrder);
   handleSorting(accessor, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField && tableData) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });

      setTableData(sorted);
    }
  }

  return (
    <div className="w-full border-l border-green-400 border-l-4">
      <table className="w-full table-auto border-t-1">
        <thead>
          <tr className="text-left">
            {columns.map(({ label, accessor }) => {
             return <th className="border border-black px-4 py-4 hover:underline cursor-pointer" key={accessor} onClick={() => handleSortingChange(accessor)}>{label}</th>
            })}
          </tr>
        </thead>

        {!is_pending && tableData && tableData.map((conn, _) => (
          <tbody key={conn.slugslug}>
            <tr>
              <td className="border border-black px-4 py-4">{conn.identifier}{conn.is_sprinter ? 's' : ''}</td>
              <td className="border border-black px-4 py-4">{conn.station_from_name}</td>
              <td className="border border-black px-4 py-4">{conn.station_to_name}</td>
              <td className="border border-black px-4 py-4">{format(new Date(conn.leaves),  'HH:mm')} Uhr</td>
              <td className="border border-black px-4 py-4">{format(new Date(conn.arrives), 'HH:mm')} Uhr</td>
              <td className="border border-black px-4 py-4">{conn.duration.replace(':00', '')}h</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}

export default ConnectionsTable;
